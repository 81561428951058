'use strict';

/*jshint maxcomplexity:30 */

var app = angular.module('directory');

app.config(function($provide) {
  // Decorate the datepicker directive
  $provide.decorator('datepickerDirective', function($delegate, $log) {
    var directive = $delegate[0];
    var link = directive.link;
    // add new attribute to directive, contains-events
    // a function that takes a date and returns true if the day has events
    directive.$$isolateBindings.containsEvents = {
      attrName: 'containsEvents',
      mode: '=',
      optional: true
    };
    // override compile function to add a watch after linking
    directive.compile = function() {
      return function(scope, element, attrs, ctrl) {
        // apply link function
        link.apply(this, arguments);
        scope.$watch(function() {
          // watch on month changes only
          return ctrl[0].activeDate.getMonth();
        }, function(newValue, oldValue) {
          //fix for OTIX-3162
          if (newValue === oldValue || !ctrl[1].$viewValue || (attrs && attrs.source && attrs.source === 'OUT_OFF_OFFICE')) {
            return;
          }

          var thisDay = ctrl[1].$viewValue.getDate();

          var viewValueDate = ctrl[1].$viewValue;
          var activeDateValue = ctrl[0].activeDate;
          // when moving by month activeDate and the new date, $viewValue is the old date
          // as the model value hasn't been updated yet
          // when moving by day, activeDate and $viewDate are the same as
          // the model date is set prior to this watch firing in $scope.selectDate
          if (viewValueDate.toDateString() !== activeDateValue.toDateString()) {
            // user clicked on month pre/next
            // set to first of the month
            thisDay = 1;
          }
          var newDate = new Date(ctrl[0].activeDate.getFullYear(), ctrl[0].activeDate.getMonth(), thisDay);
          scope.select(newDate);
          scope.$emit('calendar_month_changed', newDate);
        });
      };
    };
    return $delegate;
  });
});

app.controller('EventListController', function($q, $rootScope, $scope, $filter, $window, $log, $sce, $location,
                                               $cookies, $modal, $timeout, User, Users, Events, Schedules, AppConfig, Messages, VideoService, VidyoMessages, Notifier,
                                               PcvcServiceSettings, OtnConfirmationModalService, CallService, ngAudio, PresenceService, EventsService, DateFormat,
                                               DateService, UserPcvcPrefSettingsService, StringService) {
  var numberOfPatientsFromAssociatedEvents, numberOfPatientsFromParticipants, isToday;
  const timeouts = [];

  $scope.dateFormat = DateFormat.short;

  $scope.datePickerOptions = {
    formatYear: 'yyyy',
    startingDay: 0,
    showWeeks: false,
    showButtonBar: false
  };

  // Set localstorage OTNDebugMode:true to put the page into debug mode enabling hidden functionality
  const isDebugMode = () => {
    const debugMode = $window.localStorage.getItem('OTNDebugMode');
    return debugMode === 'true';
  };

  const isViewingDelegatorEvents = function() {
    return $scope.model.delegator && $scope.model.delegator.userId !== $scope.user.details.userid;
  };

  const isAllowedToStartOrJoin = (event) => {
    if (isDebugMode()) {
      return true;
    }

    const eventInProgress = isInProgress(event);
    const {isWebcasting} = event;
    if (isWebcasting && !eventInProgress) {
      return false;
    }

    const isCurrentUserParticipant = EventsService.getMySystem(event) !== null;
    return !!(event.isCallable && !VideoService.isInternetExplorer() && isCurrentUserParticipant);
  };

  /*
   * TODO: This listView object should contain every field necessary to display the event in the events list. The
   * goal is to remove as much logic from the view as possible and simply have it render precomputed fields.
   *
   * CS1-2180: When viewing delegator's calendar, the delegate should see grey icons for all events
   * CS1-2181: If delegate views delegator's event, call button should not be shown
   * CS1-2191: When a delegate is looking at their delegator's calendar, there should be no delegate icon displayed.
   * CS1-3036: Only allow start/join if current user is the participant
   */
  const initListView = (event) => {
    event.listView = {
      isIconGrey: !!($scope.isDelegateView(event) || isViewingDelegatorEvents()),
      isAllowedToStartOrJoin: isAllowedToStartOrJoin(event),
      statusDescription: $scope.getListStatusDescription(event)
    };
  };

  const isInFuture = (event) => (
    DateService.getLongFormattedDate(new Date()) < DateService.getLongFormattedDate(event.startTime)
  );

  const getMissedCallText = function(event) {
    if (event.isToday && EventsService.isVvrInProgress(event)) {
      return 'Event is in progress.';
    } else if ((event.isAutoConnect || event.isBridgeNeeded) && isInFuture(event)) {
      // CS1-3134: Any auto-connect or bridge call will show this message in the event details
      return 'Call will be auto-connected.';
    }
    return '';
  };

  const formatEventStatus = (event) => {
    const {status} = event;
    if (status === 'SCHEDULED') {
      return 'Scheduled';
    } else if (status === 'PENDING') {
      return 'Pending';
    } else if (status === 'COMPLETED') {
      return 'Completed';
    } else if (status === 'CANCELLED') {
      return 'Cancelled';
    }
    return '';
  };

  const initDetailsView = (event) => {
    const hasGuestParticipants = EventsService.getGuestCount(event) > 0;
    const hasOffnetParticipants = EventsService.getOffnetCount(event) > 0;
    const hasNonMemberParticipants = hasGuestParticipants || hasOffnetParticipants;

    const vvrData = (event.vvr) ? event.vvr.vvrData : null;
    const isVvrInProgress = CallService.isVvrInProgress({
      vvrData
    });

    const isClinical = EventsService.isClinical(event);
    const isCancelled = (event.status === 'CANCELLED');
    const missedCallText = getMissedCallText(event);
    // PCVC-1480: you should be able to edit an event up til 11:59pm of the same night, hence time is ignored
    const eventTimePast = ($scope.getDateOnlyMill(event.endTime) < $scope.getDateOnlyMill(new Date()));
    const hasPatients = event.totalNumberOfPatients > 0 || event.patients.length > 0;
    const isCancelAllowed = event.policy && event.policy.isCancelAllowed;
    const isCopyAllowed = event.policy && event.policy.isCopyAllowed;
    const isEditTimeAllowed = !isCancelled && !eventTimePast && event.policy.isEditTimeAllowed;
    const isEditPatientNumAllowed = !isCancelled && event.policy.isEditPatientNumAllowed;
    // CS1-3057: Show cancel for past guest / offnet events
    const showCancel = isCancelAllowed && !isCancelled
      && (!eventTimePast || hasNonMemberParticipants || event.eventSource === 'TMC_SCHED_HUB_HOSTED') && !isVvrInProgress;
    const status = formatEventStatus(event);

    event.detailsView = {
      ...event.detailsView,
      missedCallText,
      eventTimePast,
      hasGuestParticipants,
      hasOffnetParticipants,
      hasNonMemberParticipants,
      showPatientInfo: hasPatients,
      showGuestInfo: hasGuestParticipants && !isClinical,
      showResendInvite: !isCancelled && !eventTimePast && hasNonMemberParticipants,
      showCancel,
      status,
      showCopy: isCopyAllowed,
      isEditTimeAllowed,
      isEditPatientNumAllowed
    };
  };

  const getListEvent = (requestId) => (
    $scope.model.eventList.filter((event) => event.requestId === requestId)[0]
  );

  const updateEventFromCall = (call) => {
    if (!call) {
      return;
    }
    if (Object.keys(call).length === 0) {
      //empty
      return;
    }
    const {
      eventId,
      vvrData
    } = call;
    const event = getListEvent(eventId);
    if (!event) {
      return;
    }
    if (!event.vvr) {
      event.vvr = {};
    }
    event.vvr.vvrData = {
      ...event.vvr.vvrData,
      ...vvrData
    };
  };

  // This is added here as the presence changes does not reflect otherwise
  $scope.$on('otn-realtime-presence-change', function(angularEvent, videoEvent) {
    $scope.isOnline = PresenceService.getMyPresence() === 'online';
  });

  $scope.buildParticipantsWaitingText = function(event, participantData) {
    if (event.isEnding) {
      return event.listView.participantsText;
    }
    var text = '';
    if (participantData.hosts > 0) {
      text += StringService.countToString(participantData.hosts, 'host') + ' ';
    }
    if (participantData.guests > 0) {
      text += StringService.countToString(participantData.guests, 'guest') + ' ';
    }
    if (text !== '') {
      text += (participantData.isWaiting ? 'waiting' : 'connected');
    }
    return text;
  };

  const getTodaysEventByRequestId = (requestId) => {
    const todaysEvents = $scope.model.todaysEvents || [];
    return todaysEvents.filter((event) => event.requestId === requestId)[0];
  };

  $scope.$on('event_list_update_participants', function(angularEvent, participantUpdates) {
    $log.debug('eventList:event_list_update_participants: ', participantUpdates);
    participantUpdates.forEach((participantUpdate) => {
      const event = getTodaysEventByRequestId(participantUpdate.requestId);
      if (!event) {
        return;
      }

      const numParticipants = participantUpdate.hosts + participantUpdate.guests;
      if (event.isEnding && numParticipants === 0) {
        event.isEnding = false;
      }

      event.listView = {
        ...event.listView,
        isParticipantsShown: numParticipants > 0,
        isParticipantsWaiting: participantUpdate.isWaiting,
        participantsText: $scope.buildParticipantsWaitingText(event, participantUpdate)
      };

      initDetailsView(event);
    });
  });

  //CS1-711 - if user has other selected as primaryEventType - notify that the TAC(therapeutic area of care) list has been updated
  $scope.showPCVCPreferenceModal = function(primaryEventType, tacShownAttemps) {
    if ((!primaryEventType || (primaryEventType && primaryEventType === 'CLINICAL_OTHER')) &&
      (angular.isDefined(tacShownAttemps) && tacShownAttemps <= AppConfig.notification.TACUpdatedMaxAttempt) && !sessionStorage.getItem('seenUpdatedTACList')) {
      return true;
    } else{
      return false;
    }
  };

  var initDelegators = function() {
    var deferred = $q.defer();
    Users.delegators(function(data) {
      if (data.length > 0) {
        $scope.delegators = data;
        $scope.delegators.unshift({
          contactId: $scope.userDetails.contactid,
          systemId: $scope.userDetails.myPCVCSystemId,
          userId: $scope.userDetails.userid,
          name: $filter('fullName')($scope.userDetails) + ' (myself)'
        });
      }
      deferred.resolve();
    }, function(err) {
      $log.error('Error getting delegators! ' + JSON.stringify(err));
      deferred.reject();
    });
    return deferred.promise;
  };

  var saveSelectedDelegator = function(delegatorUserId, delegatorSystemId) {
    $log.debug(`EventListController.saveSelectedDelegator() delegatorUserId=${delegatorUserId}, delegatorSystemId=${delegatorSystemId}`);
    var host = $location.host();
    var subDomain = host;
    if (host.indexOf('.') > 0) {
      subDomain = host.substring(host.indexOf('.'));
    }

    // CS1-2196: Added userid to the cookie key in case multiple delegates use the same computer and browser
    const cookieId = 'delegatorId-delegate-' + $scope.userDetails.userid;
    const cookieOptions = {
      domain: subDomain
    };

    if (delegatorUserId || delegatorSystemId) {
      const cookieValue = JSON.stringify({
        delegatorUserId,
        delegatorSystemId
      });
      $cookies.put(cookieId, cookieValue, cookieOptions);
    } else{
      $cookies.remove(cookieId, cookieOptions);
    }
  };

  const initDelegatorUserId = function() {
    var deferred = $q.defer();
    let delegatorUserId = null;
    let delegatorSystemId = null;

    let cookieValue;
    try {
      // Cookie value format: { delegatorUserId, delegatorSystemId }'
      const cookieValueStr = $cookies.get('delegatorId-delegate-' + $scope.userDetails.userid);
      cookieValue = cookieValueStr ? JSON.parse(cookieValueStr) : {};
    } catch (e) {
      cookieValue = {};
    }

    // If delegator in cookie is not in delegators list, don't use it
    if ((cookieValue.delegatorUserId || cookieValue.delegatorSystemId) && $scope.delegators) {
      $scope.delegators.forEach((delegator) => {
        if (cookieValue.delegatorUserId && delegator.userId === cookieValue.delegatorUserId) {
          delegatorUserId = cookieValue.delegatorUserId;
        } else if (cookieValue.delegatorSystemId && delegator.systemId === cookieValue.delegatorSystemId) {
          delegatorSystemId = cookieValue.delegatorSystemId;
        }
      });
    }

    // Delegator not found in delegators list. Reset it to current user id.
    if (!delegatorUserId && !delegatorSystemId &&
      $scope.userDetails &&
      $scope.delegators &&
      $scope.delegators.length > 0) {
      delegatorUserId = $scope.userDetails.userid;
      delegatorSystemId = null;
      saveSelectedDelegator(delegatorUserId, delegatorSystemId);
    }

    // Init model
    if ($scope.delegators) {
      $scope.delegators.forEach((delegator) => {
        if ((delegatorUserId && delegator.userId === delegatorUserId) ||
          (delegatorSystemId && delegator.systemId === delegatorSystemId)) {
          $scope.model.delegator = delegator;
        }
      });
    }

    deferred.resolve();
    return deferred.promise;
  };

  const initEvents = function() {
    if ($location.search().selDate) {
      $scope.selectDate(new Date($location.search().selDate));
    } else{
      $scope.selectDate(new Date());
    }

    $scope.updateMontlyEvents(new Date(), 'month');

    if ($location.search().selEventId) {
      $scope.selectedEventId = $location.search().selEventId;
    } else{
      $scope.selectedEventId = null;
    }
  };

  $scope.init = function() {
    $scope.tsmSearchUri = $sce.trustAsResourceUrl(AppConfig.links.tsm.base + '/' + AppConfig.links.tsm.search);
    $scope.participantBaseUri = AppConfig.links.directory.base;
    $scope.guestAppURL = AppConfig.guestAppURL;

    //$scope.preCallBlockUI = blockUI.instances.get('eventBlocker');
    $scope.prevDay = null;
    $scope.numofPatients = 0;
    $scope.showPatientNames = false;
    $scope.showPatientNamesLabel = 'Show';
    $scope.isPlacingCall = false;
    $scope.calendarStartDate = new Date();

    $scope.isOnline = PresenceService.getMyPresence() === 'online';
    $scope.model = {
      eventList: null,
      selDate: null,
      eventsPerDay: [],
      delegator: null
    };
    $scope.displayDetail = false;
    $scope.delegators = [];

    $scope.user = User;
    if (!$scope.user.details.services.pcvc3) {
      $location.path('/');
    } else{
      if (User.details.videoPluginViewedOn !== '') {
        //TODO check for videoPluginViewedOn is workaround for SELFSRV-512, need to find generic way to skip such prompt when user is in first time login workflow.
        //if videoPluginViewedOn is empty it means user should be in process of navigation to video splash view and no need to prompt for preferences.

        // TODO: This already gets selected by UserSettings, triggered in app.js. It is redundant and should be refactored out:
        PcvcServiceSettings.list({}, function(resolution) {
          var pcvcSettings = resolution;
          User.details.primaryAgeGroup = $filter('getByProperty')('name', 'primaryAgeGroup', pcvcSettings) ? $filter('getByProperty')('name', 'primaryAgeGroup', pcvcSettings).value : '';
          User.details.primaryEventType = $filter('getByProperty')('name', 'primaryEventType', pcvcSettings) ? $filter('getByProperty')('name', 'primaryEventType', pcvcSettings).value : '';

          if (!User.details.primaryAgeGroup || $scope.showPCVCPreferenceModal(User.details.primaryEventType, User.details.tacShownAttempt)) {
            $location.path('/pcvcSettingsPreferences').search('redirectUri', '/eventList');
            return true;
          }
        });
      }
    }

    // Because the delegator in cookie may no longer be applicable to the current user, we fetch the delegators and set
    // the delegator user id before initializing the events.
    initDelegators()
      .then(initDelegatorUserId)
      .then(initEvents);
  };

  $scope.$on('calendar_month_changed', function(angEvent, newMonth) {
    $scope.updateMontlyEvents(newMonth, 'month');
  });

  const getDelegatorName = function(event) {
    if (!event.isDelegatorEvent) {
      return '';
    }

    const delegators = $scope.delegators || [];
    const delegatorContactIds = delegators.map((delegator) => delegator.contactId);
    const delegatorParticipants = event.participants.filter((participant) => (
      participant.type === 'PCVC' &&
      participant.contactId &&
      delegatorContactIds.includes(participant.contactId)
    ));

    if (delegatorParticipants.length > 0) {
      return delegatorParticipants[0].name;
    }
    return (delegatorParticipants.length > 0) ?
      delegatorParticipants[0].name :
      '';
  };

  $scope.isDelegateView = function(event) {
    return event.isDelegatorEvent && event.requestor.id === $scope.user.details.contactid;
  };

  $scope.onChangeDelegator = function() {
    $scope.model.isEventListLoaded = false;
    $scope.model.eventList = [];
    $scope.model.todaysEvents = [];
    $scope.model.eventsPerDay = [];

    const delegatorUserId = $scope.model.delegator ? $scope.model.delegator.userId : null;
    const delegatorSystemId = $scope.model.delegator ? $scope.model.delegator.systemId : null;
    saveSelectedDelegator(delegatorUserId, delegatorSystemId);

    $scope.getEventList($scope.model.selDate);
    $scope.updateMontlyEvents($scope.model.selDate, 'month');
    $scope.showCalendar();
    $scope.selectedEvent = null;
  };

  $scope.isP2PJoinOrCall = function(event) {
    return !event.isMultipoint && CallService.isP2PJoin(event);
  };


  //Added printing functionality
  $scope.printDiv = function(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var popupWin = window.open('', '_blank', 'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
      popupWin.window.focus();
      popupWin.document.write('<!DOCTYPE html><html><head>' +
        '<link rel="stylesheet" type="text/css" href="../styles/eventListPrint.css" />' +
        '</head><body onload="window.print(); window.close();"><div class="reward-body">' + printContents + '</div></html>');
      //popupWin.onbeforeunload = function (event) {
      window.onbeforeunload = function(event) {
        return '.\n';
      };

      popupWin.onabort = function(event) {
        popupWin.document.close();
        popupWin.close();
      };
    } else{
      popupWin.document.write('<html><head>' +
        '<link rel="stylesheet" type="text/css"  href="../../styles/eventListPrintFF.css" />' +
        '</head><body onload="window.print()">' + printContents + '</html>');
    }


    Events.auditLoggingList({actionType: 'PRINT_LIST'}, createEventsListAudit($scope.model.todaysEvents, 'PRINT_LIST'));
    popupWin.document.close();

    return true;
  };

  $scope.printDetails = function(eventId) {
    $window.print();
    Events.auditLogging({id: eventId, actionType: 'PRINT_DETAILS'});
  };

  $scope.selectDate = function(thisDate) {
    $scope.model.selDate = thisDate;
    $scope.model.eventList = null;
    $scope.model.isEventListLoaded = false;
    $scope.getEventList(thisDate);
    $scope.prevDay = thisDate.getDay();
    $scope.model.todaysEvents = $filter('filter')($scope.model.eventList, {
      eventDate: ($filter('date')($scope.model.selDate, 'yyyy-MM-dd'))
    });
    $scope.processSelectedDayEvents($scope.model.todaysEvents);
  };

  $scope.getDelegatorByUserId = function(userId) {
    return $scope.delegators.find(d => d.userId === userId);
  };

  $scope.isSelectedDateToday = function() {
    var todaysDate = ($filter('date')(new Date(), 'yyyy-MM-dd'));
    var selectedDate = ($filter('date')($scope.model.selDate, 'yyyy-MM-dd'));
    return todaysDate === selectedDate;
  };

  $scope.isSelectedDatePast = function() {
    var todaysDate = new Date(($filter('date')(new Date(), 'yyyy-MM-dd')));
    var selectedDate = new Date(($filter('date')($scope.model.selDate, 'yyyy-MM-dd')));
    return selectedDate < todaysDate;
  };

  /**
   * Polls for participant status a finite number of times. Triggered when there is a known expected
   * change in the participants list (i.e. when leaving a call or ending a call).
   *
   * TODO: In the future this should be replaced with a websockets implementation instead of polling.
   *
   * @param times - Number of times to poll
   * @param interval - Interval between requests
   */
  $scope.pollEventListParticipants = (times = 5, interval = 3000) => {
    if (times <= 0) {
      return;
    }
    $scope.refreshEventListParticipants();
    const timeout = setTimeout(() => {
      $scope.pollEventListParticipants(times - 1, interval);
    }, interval);
    timeouts.push(timeout);
  };

  $scope.$on('CallEnded', function(angularEvent, call) {
    updateEventFromCall(call);

    initDelegatorUserId();

    // Ensures new adhoc calls are added to today's event list
    const today = new Date();
    $scope.selectDate(today);
    $scope.updateMontlyEvents(today, 'day');

    $scope.pollEventListParticipants();
  });

  const findParticipantBySystemOrContactId = (participants, systemId, contactId) => {
    const filteredParticipants = participants.filter((participant) => (
      (systemId && participant.id === systemId) ||
      (contactId && participant.contactId === contactId)
    ));
    return filteredParticipants.length > 0 ? filteredParticipants[0] : null;
  };

  $scope.$on('event_created', function(angEvent, newEventData) {
    $log.debug('eventList: Received "event_created" event. Initializing delegator, refreshing event list...');
    // Find delegator participant in the created event, change delegator selection accordingly
    const {memberParticipants} = newEventData;
    const delegatorParticipants = $scope.delegators.filter(
      (delegator) => (!!findParticipantBySystemOrContactId(memberParticipants, delegator.systemId, delegator.contactId))
    );
    // If multiple delegators are participants, pick the first one
    const delegatorParticipant = (delegatorParticipants.length > 0) ? delegatorParticipants[0] : null;

    if (delegatorParticipant) {
      saveSelectedDelegator(delegatorParticipant.userId, delegatorParticipant.systemId);
      initDelegatorUserId();
    }

    $scope.getEventList($scope.model.selDate);
    $scope.updateMontlyEvents(newEventData.startTime, 'day');
  });

  $scope.$on('user-pcvc-pref-settings-changed', function(angEvent, changedData) {
    $log.debug('eventList: received user-pcvc-pref-settings-changed %s', JSON.stringify(changedData));
    var newSettings = changedData.settings;
    var updateUserPCVCSettingInEvent = function(event) {
      if (!event) {
        return;
      }
      if (!event.preferences) {
        return;
      }
      $log.debug('eventList: update-user-pcvc-pref-settings, eventId:%s', event.requestId);
      if (event.preferences.userId !== changedData.userId) {
        return;
      }
      event.adminText = UserPcvcPrefSettingsService.renderContact({
        name: newSettings.contactName,
        phone: newSettings.contactPhone,
        email: newSettings.contactEmail
      });
    };
    updateUserPCVCSettingInEvent($scope.selectedEvent);
    angular.forEach($scope.model.todaysEvents, updateUserPCVCSettingInEvent);
  });

  $scope.eventCategoryToLabel = (event) => {
    return EventsService.getEventCategoryLabel(event);
  };

  // expect an array of participants
  $scope.numberOfPatients = function(event) {
    var numberOfPatients = 0;
    if (event.category === 'CLINIC' && (event.isGroup === false && event.isTSMClinic === false)) {
      numberOfPatients = numberOfPatientsFromParticipants(event);
    } else if (event.category === 'CLINIC' && (event.isGroup === true && event.isTSMClinic === true)) {
      numberOfPatients = numberOfPatientsFromAssociatedEvents(event);
    } else if (event.category === 'CLINIC' && (event.isGroup === true && event.isTSMClinic === false)) {
      numberOfPatients = numberOfPatientsFromParticipants(event);
    } else if (event.category === 'CLINICAL') {
      numberOfPatients = 1;
    } else{
      numberOfPatients = 0;
    }
    return numberOfPatients;
  };

  numberOfPatientsFromAssociatedEvents = function(event) {
    var numberOfPatients = 0;
    if (angular.isDefined(event.associatedEvents)) {
      var scheduledOnly = $filter('filter')(event.associatedEvents, {
        status: 'SCHEDULED'
      });
      numberOfPatients = (scheduledOnly.length || 0);

      var pendingOnly = $filter('filter')(event.associatedEvents, {
        status: 'PENDING'
      });
      numberOfPatients += (pendingOnly.length || 0);
    }
    return numberOfPatients;
  };

  numberOfPatientsFromParticipants = function(event) {
    var numberOfPatients = 0;
    if (angular.isDefined(event.participants)) {
      angular.forEach(event.participants, function(participant) {
        if (participant.numOfPatients) {
          numberOfPatients = numberOfPatients + participant.numOfPatients;
        }
      });
    }
    return numberOfPatients;
  };

  //Returns the 1st participant that is not my system
  $scope.remoteLookup = function(participants) {
    if (participants.length > 1) {
      //Filters out all participants with isMySystem set to false (built-in filter in angularjs)
      var notMySystem = $filter('filter')(participants, {
        isMySystem: false
      });
      if (notMySystem.length > 0) {
        return notMySystem[0];
      }
    }
    return null;
  };

  $scope.eventCallable = function(event) {
    if (event.notCallableReason !== null) {
      return false;
    }
    if (event.status !== 'SCHEDULED' && event.status !== 'COMPLETED' && event.status !== 'CANCELLED') {
      return false;
    }
    return true;
  };

  $scope.setEventListTitle = function(event) {
    var line = event.title || '';
    switch (event.category) {
      case 'CLINIC':
      case 'CLINICAL':
        if (!line) {
          line = 'Clinical event';
        }
        // TODO: CS1-2673: Why is setEventListTitle updating num patients??
        if ($scope.numofPatients === 0) {
          event.totalNumberOfPatients = $scope.numberOfPatients(event);
        } else{
          event.totalNumberOfPatients = $scope.numofPatients;
          $scope.numofPatients = 0;
        }
        break;
    }
    return line;
  };

  $scope.setEventDetailTitle = function(event) {
    var line = '';
    switch (event.category) {
      case 'CLINIC':
      case 'CLINICAL':
        line = event.title || '';
        if (line === '') {
          line = $scope.setEventListTitle(event);
        }
        break;
      default:
        // handles LEARNING and MEETING events
        line = event.title || '';
    }
    return line;
  };

  isToday = function(event) {
    var today, eventTakesPlaceToday;
    eventTakesPlaceToday = false;
    today = $filter('date')(new Date(), 'yyyy-MM-dd');
    if (event.eventDate === today) {
      eventTakesPlaceToday = true;
    }
    return eventTakesPlaceToday;
  };

  $scope.isEventEnded = (event) => (
    DateService.getLongFormattedDate(new Date()) > DateService.getLongFormattedDate(event.endTime)
  );

  const isInProgress = (event) => (
    (DateService.getLongFormattedDate(new Date()) >= DateService.getLongFormattedDate(event.startTime) &&
    DateService.getLongFormattedDate(new Date()) < DateService.getLongFormattedDate(event.endTime))
  );

  $scope.getListStatusDescription = function(event) {
    const eventStatus = (event) => {
      const {
        status,
        eventSource
      } = event;
      if (status === 'CANCELLED') {
        return 'Cancelled';
      }
      if (status === 'PENDING') {
        return 'Pending';
      }
      if (eventSource === 'ADHOC') {
        return 'Call';
      }
      return 'Scheduled';
    };

    const preposition = (event) => (
      (event.isDelegatorEvent && event.participants.length === 2) ? 'between' : 'with'
    );

    const participantsToString = (event) => {
      if (event.participants.length > 2) {
        return 'multiple participants';
      }
      const participantsExceptMySystem = event.participants.filter((participant) => (!participant.isMySystem));
      const participantNames = participantsExceptMySystem.map(participant => participant.name);
      return participantNames.join(' and ');
    };

    const viaString = (event) => {
      const guestCount = EventsService.getGuestCount(event);
      const offnetCount = EventsService.getOffnetCount(event);
      if (event.participants.length !== 2) {
        return '';
      } else if (guestCount > 0) {
        return 'via email';
      } else if (offnetCount > 0) {
        return 'via non-member system';
      }
      return '';
    };

    return `${eventStatus(event)} ${preposition(event)} ${participantsToString(event)} ${viaString(event)}`;
  };

  $scope.getStatusActivity = function(event) {
    var output, toCallSystemName, numberOfParticipants, continueFlag;
    continueFlag = true;
    output = {};
    output.list = '';
    output.listPostTime = '';
    output.detail = '';

    if (event.status !== 'SCHEDULED') {
      continueFlag = false;
    }

    if (continueFlag) {
      var remoteSystem = $scope.remoteLookup(event.participants);
      var consultingSystem = EventsService.getConsultantSystem(event);
      var mySystem = EventsService.getMySystem(event);
      toCallSystemName = '';
      if (remoteSystem !== null) {
        if (remoteSystem.type === 'GUEST') {
          toCallSystemName = 'via OTNinvite';
        } else{
          toCallSystemName = remoteSystem.name;
        }
      } else if (remoteSystem === null && consultingSystem !== null) {
        toCallSystemName = consultingSystem.name;
      }
      numberOfParticipants = event.participants.length || 0;

      if (mySystem === null) {
        output.list = 'to attend';
        output.listPostTime = toCallSystemName;
        output.detail = '';
        continueFlag = false;
      }

      if (continueFlag) {
        if ((event.eventSource === 'GUEST_LINK') || (event.eventSource === 'ADHOC') && (event.isBridgeNeeded !== true)) {
          if (event.isAutoConnect !== true) {
            output.list = 'to call ' + toCallSystemName;
            output.listPostTime = toCallSystemName;
            output.detail = '';
          }
        } else{
          if (event.isBridgeNeeded === true && numberOfParticipants > 2) {
            output.list = '  to auto connect with multiple participants';
            output.listPostTime = ' multiple participants';
            output.detail = '';
          }
          if (event.isBridgeNeeded === true && numberOfParticipants === 2) {
            output.list = 'to auto connect with ' + toCallSystemName;
            output.listPostTime = toCallSystemName;
            output.detail = 'Call will be auto connected';

          }
          if (event.isAutoConnect === true && numberOfParticipants === 2) {
            output.list = 'to auto connect with ' + toCallSystemName;
            output.listPostTime = toCallSystemName;
            output.detail = 'Call will be auto connected';
          }
          if (event.isAutoConnect === false && event.isBridgeNeeded === false && numberOfParticipants === 2) {
            output.list = 'to call ' + toCallSystemName;
            output.listPostTime = toCallSystemName;
            output.detail = '';
          }
        }

      }
    }

    output.list.trim();
    output.detail.trim();
    return output;
  };

  var getEventPatient = function(patient, auditAction) {
    if (auditAction === 'VIEW_LIST_PHI' && patient && ( patient.firstName || patient.lastName || patient.patientId)) {
      return {
        patientId: patient.patientId,
        firstName: patient.firstName,
        lastName: patient.lastName
      }
    } else{
      return null;
    }

  };

  var getAssociatedEventsAudit = function(associatedEvents, auditAction) {
    var auditAssociatedEvents = [];
    if (auditAction === 'VIEW_LIST_PHI') {
      angular.forEach(associatedEvents, function(associatedEvent) {
        auditAssociatedEvents.push({
          requestId: associatedEvent.requestId,
          endTime: associatedEvent.endTime,
          startTime: associatedEvent.startTime,
          patient: getEventPatient(associatedEvent.patient, 'VIEW_LIST_PHI'),
        });
      });
    }
    return auditAssociatedEvents;
  };

  var createEventsListAudit = function(todayEvents, auditAction) {
    var auditEvents = [];

    angular.forEach(todayEvents, function(event) {
      var auditEvent = {
        eventId: event.requestId,
        title: event.title,
        category: event.category,
        //source: event.sourceOrig,
        startTime: event.startTime,
        endTime: event.endTime,
        participants: event.participants,
        patient: getEventPatient(event.patient, auditAction)
      };
      if (auditAction === 'VIEW_LIST_PHI') {
        auditEvent.associatedEvents = getAssociatedEventsAudit(event.associatedEvents, auditAction);
      }
      auditEvents.push(auditEvent);
    });
    return {eventsList: auditEvents};
  };

  var auditGetEventList = function() {
    var actionType = 'VIEW_LIST';

    if ($scope.showPatientNames) {
      var actionType = 'VIEW_LIST_PHI';
    }

    Events.auditLoggingList({actionType: actionType}, createEventsListAudit($scope.model.todaysEvents, actionType));
  };

  $scope.showHidePatientNames = function() {

    $scope.showPatientNames = !$scope.showPatientNames;
    $scope.showPatientNamesLabel = $scope.showPatientNames ? 'Hide' : 'Show';

    if ($scope.showPatientNames) {
      auditGetEventList();
    }
  };

  const patientDetailsToString = (event) => {
    const numPatients = event.totalNumberOfPatients;
    return `${numPatients} patient${(numPatients !== 1) ? 's' : ''}`;
  };

  const guestDetailsToString = (event) => {
    const guestParticipants = EventsService.getGuestParticipants(event);
    const names = guestParticipants.map((participant) => participant.name);
    return `${names.length} guest${(names.length !== 1) ? 's' : ''}: ${names.join(', ')}`;
  };

  /**
   * Returns the string "(N patients)" or "(N guests)"
   * @param event
   * @returns {string}
   */
  const getPatientSummary = function(event) {
    const numPatients = event.totalNumberOfPatients;
    const numGuests = EventsService.getGuestCount(event);

    if (numPatients > 0) {
      return `(${numPatients} patient${(numPatients !== 1) ? 's' : ''})`;
    } else if (numGuests > 0) {
      return `(${numGuests} guest${(numGuests !== 1) ? 's' : ''})`;
    }
    return '';
  };

  /**
   * Returns a comma separated list of patients or guests.
   * @param event
   * @returns {string}
   */
  const getPatientDescription = function(event) {
    const isClinical = EventsService.isClinical(event);
    const isGuestLink = EventsService.isGuestLink(event);

    if (isClinical && !isGuestLink && event.totalNumberOfPatients > 0) {
      return patientDetailsToString(event);
    } else if (isGuestLink) {
      return guestDetailsToString(event);
    }
    return '';
  };

  $scope.getStatusDescription = function(event) {
    var line = '';
    switch (event.status) {
      case 'CANCELLED':
        line = 'Cancelled';
        break;
      case 'CLOSED':
        line = 'Closed';
        break;
      case 'COMPLETE':
        line = 'Complete';
        break;
      case 'IN_PROGRESS':
        line = 'In Progress';
        break;
      case 'PENDING':
        line = 'Pending';
        break;
      case 'REJECTED':
        line = 'Rejected';
        break;
      case 'REQUESTED':
        line = 'Requested';
        break;
      case 'SCHEDULED':
        line = 'Scheduled';
        break;
      case 'UNSCHEDULED':
        line = 'Unscheduled';
        break;
      default:
        line = event.title;
        break;
    }
    return line.trim();
  };

  $scope.processAssociatedEvents = function(event) {
    // look for patients in associated events
    if (event.patient && (event.patient.patientId || event.patient.isMasked)) {
      // if patient directly part of event (not an associated event)
      // the event start end time are the same
      // as the patients appointment start and end time
      event.patient.startTime = event.startTime || '';
      event.patient.endTime = event.endTime || '';
      $scope.maskPatient(event.patient);
      event.patients.push(event.patient);
    } else if (typeof event.associatedEvents !== 'undefined') {
      angular.forEach(event.associatedEvents, function(associatedEvent) {
        if (associatedEvent.status === 'SCHEDULED' || associatedEvent.status === 'PENDING') {
          if (associatedEvent.patient && (associatedEvent.patient.patientId || associatedEvent.patient.isMasked)) {
            associatedEvent.patient.startTime = associatedEvent.startTime;
            associatedEvent.patient.endTime = associatedEvent.endTime;
            $scope.maskPatient(associatedEvent.patient);
            event.patients.push(associatedEvent.patient);
          }
          $scope.processParticipants(associatedEvent.participants);
          angular.forEach(associatedEvent.participants, function(participant) {
            var foundIt = $filter('filter')(event.participants, {
              id: participant.id
            });
            if (foundIt.length === 0) {
              event.participants.push(participant);
            }
          });
        }
      });
    }
    $log.debug('end processAssociatedEvents');
  };

  $scope.processParticipants = function(participants) {
    angular.forEach(participants, function(participant, key) {
      participant.directoryUri = null;
      if (participant.type === 'LEGACY') {
        participant.directoryUri = '#/profile/places/' + participant.publishedProfileId + '?goto=system-' + participant.id;
      } else if (participant.type === 'PCVC') {
        if (participant.publishedProfileId !== null) {
          participant.directoryUri = '#/profile/people/' + participant.publishedProfileId;
        }
      }
    });
  };

  $scope.unboxTsmSerialClinics = function(events) {
    var unboxedEvents = [];
    angular.forEach(events, function(event, key) {
      if (event.isTSMClinic && !event.isGroup) {
        angular.forEach(event.associatedEvents, function(associatedEvent, key) {
          if (associatedEvent.status === 'SCHEDULED' || associatedEvent.status === 'COMPLETED') {
            unboxedEvents.push(associatedEvent);
          }
        });
      } else{
        unboxedEvents.push(event);
      }
    });
    return unboxedEvents;
  };

  $scope.maskPatient = function(patient) {
    if (patient.isMasked) {
      // if masked all values from midletier will be null
      // for display use * to indicate data is masked
      patient.firstName = '*****';
      patient.lastName = '*****';
      patient.dateOfBirth = '*****';
      patient.phoneHome = '*****';
      patient.ohipNumber = '*****';
    }
  };

  $scope.processEvents = function(events) {
    const unboxedEvents = $scope.unboxTsmSerialClinics(events);
    unboxedEvents.forEach((event) => {
      event.patients = [];
      event.eventDate = $filter('date')(event.startTime, 'yyyy-MM-dd');
      event.isDelegatorEvent = EventsService.isDelegatorEvent(event);
      $scope.processAssociatedEvents(event);
      $scope.processParticipants(event.participants);
      $scope.processAttachments(event);
      event.listTitle = $scope.setEventListTitle(event);
      event.detailTitle = $scope.setEventDetailTitle(event);
      const connectString = $scope.getStatusActivity(event);
      event.listPatientSummary = getPatientSummary(event);
      event.listPatientDescription = getPatientDescription(event);
      event.delegatorName = getDelegatorName(event);
      event.detailConnectText = connectString.detail;
      event.isCallable = $scope.eventCallable(event);
      event.eventCategory = EventsService.getEventCategoryLabel(event);
      event.webcastUri = null;
      if (event.isWebcasting === true) {
        event.webcastUri = AppConfig.links.hub.webcasting + '/videos/' + event.requestId;
      }
      event.eventDate = $filter('date')(event.startTime, 'yyyy-MM-dd');
    });
    return unboxedEvents;
  };

  $scope.processSelectedDayEvents = function(events) {
    if (!events) {
      return;
    }
    events.forEach((event) => {
      event.isMultipoint = EventsService.isMultipoint(event);
      event.isToday = isToday(event);
      event.inProgress = isInProgress(event);
      event.isDelegatorEvent = EventsService.isDelegatorEvent(event);
      event.delegatorName = getDelegatorName(event);
      if (event.preferences) {
        event.adminText = UserPcvcPrefSettingsService.renderContact({
          name: event.preferences.gladminName,
          phone: event.preferences.gladminPhone,
          email: event.preferences.gladminEmail
        });
      } else{
        event.adminText = UserPcvcPrefSettingsService.renderContact({});
      }

      initListView(event);
    });
  };

  $scope.processAttachments = function(event) {
    angular.forEach(event.attachments, function(attachment, key) {
      if (attachment.fileId !== null) {
        attachment.url = AppConfig.services.files.base + attachment.fileId + '?api_access_token=' + User.token.accessToken;
      } else{
        attachment.url = AppConfig.services.files.jackRabbit + '/' + attachment.repositoryURL + '?access_token=' + User.token.accessToken;
      }
    });
  };
  $scope.hasEvents = function(date) {
    // filter list looking for today's events
    var calendarDay = $filter('date')(date, 'yyyy-MM-dd');
    return $scope.model.eventsPerDay[calendarDay] !== undefined;
  };

  $scope.showDetail = function(event) {
    $log.debug('$scope.showDetail() event: ', event);
    const eventDetails = document.getElementById('selected-event-details');
    if (eventDetails) {
      eventDetails.scrollTop = 0;
    }

    if (!$scope.displayDetail) {
      $scope.displayDetail = true;
    }

    initDetailsView(event);
    $scope.selectedEvent = event;
    $scope.isClinicalDetail = EventsService.isClinical(event);

    if (!$scope.isSelectedDatePast()) {
      CallService.fetchRTEventParticipantsStatus(event);
    } else{
      $log.warn('Participants not being fetched. Selected date is in the past.');
    }

    Events.auditLogging({id: event.requestId, actionType: 'VIEW_DETAILS'});

  };

  $scope.getDateOnlyMill = function(time) {
    var dateStr = $filter('date')(time, 'MM/dd/yyyy');
    var dateRes = new Date(dateStr);
    return dateRes.getTime();
  };

  $scope.$on('$destroy', function() {
    timeouts.forEach((timeout) => clearTimeout(timeout));
  });

  $scope.showCalendar = function() {
    $scope.displayDetail = false;
  };

  $scope.prevNextDay = function(direction) {
    $scope.model.selDate = new Date($scope.model.selDate);
    $scope.model.selDate.setDate(($scope.model.selDate.getDate() + direction));
    $scope.selectDate($scope.model.selDate);
    $scope.showCalendar();
  };

  var validatePlaceCall = function(event) {
    if (!User.details.primaryAgeGroup) {
      $location.path('/pcvcSettingsPreferences').search('redirectUri', '/eventList').search('selDate', $filter('date')($scope.model.selDate));
      return false;
    }

    // TODO: CS1-2673: This should be moved to CallService
    if (event !== 'undefined' && event && $scope.isP2PJoinOrCall(event)) {
      if (DateService.getLongFormattedDate(new Date()) >= DateService.getLongFormattedDate(event.endTime)) {
        $log.warn('validatePlaceCall: Event has ended endTime ' + event.endTime);

        var message = VidyoMessages.call.cantCallP2PJoinOrCall.message;
        message = message.replace('<TIME>', DateService.getLongFormattedDate(event.endTime).toTimeString());
        Notifier.createMessage('warning', message, VidyoMessages.call.cantCallP2PJoinOrCall.solution);
        $scope.isPlacingCall = false;
        return false;
      }
    }

    return true;
  };

  var initCallParams = function(event) {
    var call = {
      caller: {
        presence: PresenceService.getMyPresence()
      },
      callee: {
        presence: undefined
      },
      eventId: event.requestId,
      vvrId: undefined
    };

    angular.forEach(event.participants, function(participant, key) {
      if (participant.vidyoEmail !== User.details.vidyousername) {
        call.callee.id = participant.id;
        call.callee.type = participant.type;
        call.callee.contactId = participant.contactId;
      }
    });

    return call;
  };

  const callStartSuccess = function(call) {
    updateEventFromCall(call);
    $scope.disableCallButton = true;
    $scope.isPlacingCall = false;
  };

  const callStartFailure = function(rejection) {
    $scope.disableCallButton = true;
    $scope.isPlacingCall = false;

    if (angular.isDefined(rejection.error) && rejection.error === 'E_CALL_EVENT_CANCELLED') {
      $scope.getEventList($scope.model.selDate);
      $scope.showCalendar();
    }
  };

  /**
   * Start an existing event and connect the current user to it.
   *
   * @param event
   */
  const startCall = function(event) {
    if (!validatePlaceCall(event)) {
      $scope.isPlacingCall = false;
      return;
    }

    const call = initCallParams(event);
    CallService.startCall(call)
      .then(callStartSuccess)
      .catch(callStartFailure);
  };

  /**
   * Connect the current user to an event that is in progress.
   *
   * @param event
   */
  const joinCall = function(event) {
    if (!validatePlaceCall(event)) {
      $scope.isPlacingCall = false;
      return;
    }

    const call = initCallParams(event);
    CallService.joinCall(call)
      .then(callStartSuccess)
      .catch(callStartFailure);
  };

  const onJoinCancel = () => {
    $scope.isPlacingCall = false;
  };

  /**
   * Connect the current user to an existing event that's already in progress. Used for multipoint events for example.
   *
   * @param event
   * @param confirmed
   * @returns {boolean}
   */
  const joinMe = function(event, confirmed) {
    // Delegator only event - don't allow join
    if (event.isDelegatorEvent) {
      Notifier.createMessage('information', VidyoMessages.call.cantJoinNotParticipant.message, '');
      $scope.isPlacingCall = false;
      return false;
    }

    if (!confirmed) {
      $scope.openJoinMeWarningModal(event, () => joinMe(event, true), onJoinCancel);
    } else{
      return joinCall(event);
    }
  };

  $scope.openJoinMeWarningModal = function(event, onJoin, onCancel) {
    $modal.open({
      templateUrl: 'views/partials/joinMeWarningModal.html',
      backdrop: 'static', // Don't allow clicking outside of the box to
      // dismiss the modal
      keyboard: false, // Don't allow the escape key to dismiss the modal
      windowClass: 'modal-small',
      resolve: {
        selectedEvent: function() {
          return event;
        }
      },
      controller: function($scope, $modalInstance, selectedEvent) {
        $scope.selectedEvent = selectedEvent;

        $scope.joinMe = function() {
          $modalInstance.dismiss('cancel');
          onJoin();
        };

        $scope.cancel = function() {
          $modalInstance.dismiss('cancel');
          onCancel();
        };
      }
    });
  };

  // If this is a delegator-only event the start/join button should be enabled always
  $scope.isStartOrJoinDisabled = (event) => {
    if (event.eventSource === 'TMC_SCHED_HUB_HOSTED' && event.outlookStatusCd === 'REJECTED_HOST') {
      return true;
    } else{
      return !event.isDelegatorEvent && (!$scope.isOnline || $scope.isPlacingCall);
    }
  };

  const updateEventVvr = (event) => {
    return new Promise((resolve, reject) => {
      CallService.findVvr({
        eventId: event.requestId
      }).then((data) => {
        event.vvr = data;
        initDetailsView(event);
        resolve(event);
      }).catch((err) => {
        reject(err);
      });
    });
  };

  /**
   * This will be the only entry point to $scope.placeCall and $scope.joinMe.
   *
   * Logic:
   * - Get event VVR status
   * - If VVR is in progress, execute join
   * - Otherwise, execute start
   *
   * @param event - The event object for which we want to start/join a call
   * @param $event - The click event
   */

  //FIX for Jira: CS1-3365
  $scope.confirmStartOrJoin = (event, $event) => {

    $log.debug('confirmStartOrJoin: event - ', event);
    //show confirmation modal only if event is MP & !event.inProgress
    if (event.isMultipoint && !isInProgress(event)) {
      //using modal service from otn.components
      //define the modal options
      var modalOptions;
      modalOptions = {
        closeButtonText: 'Cancel',
        actionButtonText: 'Connect',
        actionHeaderText: 'Start/Join Event',
        questionText: 'WARNING: Dialing this call will attempt to connect all scheduled PCVC users and room-based participants.',
        explanationText: 'Are you sure you want to proceed?'
      };
      //if user clicks cancel - modal disappear automatically;
      OtnConfirmationModalService.showModal({}, modalOptions).then((result) => {
        //if they click the “OK” button (or in our case Unpublish) you can process the request
        $scope.startOrJoin(event, $event);
      });
    } else{
      $scope.startOrJoin(event, $event);
    }

  };
  //*** end of fix for CS1-3365

  $scope.startOrJoin = (event, $event) => {
    if ($scope.isPlacingCall) {
      return;
    }
    $scope.isPlacingCall = true;

    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();
    }

    updateEventVvr(event).then(() => {
      const vvrState = event.vvr.vvrData.state;
      if (vvrState === 'Active') {
        startCall(event);
      } else if (vvrState === 'InProgress') {
        joinMe(event);
      } else{
        $log.error(`startOrJoin(): Invalid VVR status: ${vvrState}`);
        Notifier.createMessage(
          'error',
          VidyoMessages.call.cantStartConference.message,
          VidyoMessages.call.cantStartConference.solution,
          `Invalid VVR status: ${vvrState}`
        );
        $scope.isPlacingCall = false;
      }
    }).catch((err) => {
      $log.error(`startOrJoin(): Failed to retrieve VVR status for event ${event.requestId}. `, err);
      Notifier.createMessage(
        'error',
        VidyoMessages.call.cantStartConference.message,
        VidyoMessages.call.cantStartConference.solution,
        `Failed to retrieve VVR status for event ${event.requestId}. ` + JSON.stringify(err)
      );
      $scope.isPlacingCall = false;
    });
  };

  $scope.getEventList = function(date) {
    var strDate = $filter('date')(date, 'yyyy-MM-dd');
    var endDate = $filter('date')(date, 'yyyy-MM-dd');

    var params = {
      startDate: strDate,
      endDate: endDate
    };

    const {
      delegator
    } = $scope.model;
    if (delegator && delegator.userId) {
      params.delegator = delegator.userId;
    } else if (delegator && delegator.systemId) {
      params.systemId = delegator.systemId;
    }

    Events.my(params, function(result) {
      if (Array.isArray(result.events)) {
        $scope.model.eventList = $scope.processEvents(result.events);
        $scope.model.todaysEvents = $filter('filter')($scope.model.eventList, {
          eventDate: ($filter('date')($scope.model.selDate, 'yyyy-MM-dd'))
        });
        $scope.processSelectedDayEvents($scope.model.todaysEvents);
        $scope.refreshEventListParticipants();
        $timeout(function() {
          $scope.selectEventDetails();
        }, 1000);
      } else{
        $scope.model.eventList = [];
        $scope.model.todaysEvents = [];
      }
      auditGetEventList();
      $scope.model.isEventListLoaded = true;
    }, function() {
      $scope.model.isEventListLoaded = true;
      Notifier.createMessage('error', Messages.eventList.list.failed.message, Messages.eventList.list.failed.solution);
    });
  };

  // Get one month's events
  $scope.updateMontlyEvents = function(date, type) {
    var strDate, endDate;
    if (type === 'month') {
      var lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      strDate = $filter('date')(firstDayOfMonth, 'yyyy-MM-dd');
      endDate = $filter('date')(lastDayOfMonth, 'yyyy-MM-dd');
      $scope.model.eventsPerDay = [];
    } else if (type === 'day') {
      strDate = $filter('date')(date, 'yyyy-MM-dd');
      endDate = $filter('date')(date, 'yyyy-MM-dd');
      delete $scope.model.eventsPerDay[strDate];
    }

    var params = {
      startDate: strDate,
      endDate: endDate,
      type: 'compact'
    };

    const {
      delegator
    } = $scope.model;
    if (delegator && delegator.userId) {
      params.delegator = delegator.userId;
    } else if (delegator && delegator.systemId) {
      params.systemId = delegator.systemId;
    }

    Events.my(params, function(result) {
      $scope.updateEventsPerDay(result.events);
    }, function() {
      Notifier.createMessage('error', Messages.eventList.list.failed.message, Messages.eventList.list.failed.solution);
    });
  };

  $scope.updateEventsPerDay = function(compactEvents) {
    angular.forEach(compactEvents, function(event) {
      var eventDate = $filter('date')(event.startTime, 'yyyy-MM-dd');
      if ($scope.model.eventsPerDay[eventDate] === undefined) {
        $scope.model.eventsPerDay[eventDate] = 1;
      } else{
        $scope.model.eventsPerDay[eventDate] = $scope.model.eventsPerDay[eventDate] + 1;
      }
    });
  };

  // Converts list of participants to a string in the form: "Name at Email, Name2 at Email2, ..."
  var participantsToText = function(participants) {
    var text = '';
    angular.forEach(participants, function(participant) {
      if (participant.name && participant.email) {
        text += participant.name + ' at ' + participant.email + ', ';
      }
    });
    if (text.length > 2) {
      text = text.substring(0, text.length - 2);
    }
    return text;
  };

  $scope.confirmResendOTNinvite = function(selectedEvent) {
    $log.info('***** confirmResendOTNinvite: ', selectedEvent);

    var participantsAsText = participantsToText(selectedEvent.participants);
    var explanationText = participantsAsText ?
      ('When you click "Resend" a resend message will be emailed to ' + participantsAsText + '.') :
      '';

    //define the modal options
    var modalOptions = {
      closeButtonText: 'Cancel',
      actionButtonText: 'Resend',
      actionHeaderText: 'Resend Invite',
      questionText: 'Are you sure you want to resend this event invite?',
      explanationText: explanationText
    };

    OtnConfirmationModalService.showModal({}, modalOptions).then(function(result) {
      $log.debug('RESEND -> participants: ' + participantsAsText);
      $scope.resendOTNinvite(selectedEvent.requestId);
    });
  };

  $scope.resendOTNinvite = function(eventId) {
    $log.info('resendOTNinvite ID: ', eventId);

    Events.resend({
      id: eventId
    }, function(response) {
      $log.info('Event resend invite - ID: ', response);
      Notifier.createMessage('confirmation', Messages.otnInvite.msg06.message);
    }, function(rejection) {
      $log.error('Unable to cancel the event', rejection);
      if (rejection.data && rejection.data.error === 'EVENT_CANCELED') {
        Notifier.createMessage('error', Messages.otnInvite.eventCancelled.message, Messages.otnInvite.eventCancelled.solution);
        $scope.getEventList($scope.model.selDate);
        $scope.showCalendar();
      } else{
        Notifier.createMessage('error', Messages.otnInvite.msg07.message, JSON.stringify(rejection));
      }
    });
    $log.info('resendOTNinvite END');
  };

  $scope.confirmCancelOTNinvite = function(selectedEvent) {
    $log.info('confirmCancelOTNinvite: ', selectedEvent);

    var participantsAsText = participantsToText(selectedEvent.participants);
    var explanationTextCancel = '';

    if ($filter('date')(selectedEvent.endTime, 'MM/dd/yyyy HH:mm') < $filter('date')(new Date(), 'MM/dd/yyyy HH:mm')) {
      explanationTextCancel = ' As the event has passed, when you click "Yes" a cancellation message will not be emailed to the event participant(s).';
    } else if (participantsAsText) {
      explanationTextCancel = 'When you click "Yes" a cancellation message will be emailed to ' + participantsAsText + '.';
    }

    //define the modal options
    var modalOptions = {
      closeButtonText: 'No',
      actionButtonText: 'Yes',
      actionHeaderText: 'Cancel Event',
      questionText: 'Are you sure you want to cancel this event?',
      explanationText: explanationTextCancel
    };

    OtnConfirmationModalService.showModal({}, modalOptions).then(function(result) {
      $log.debug('Cancel OtnInvite -> event.id ' + selectedEvent.requestId);
      $scope.cancelOTNinvite(selectedEvent);
    });
  };

  $scope.cancelOTNinvite = function(event) {
    $log.info('cancelOTNinvite ID: ', event);

    updateEventVvr(event).then(() => {
      const vvrState = event.vvr.vvrData.state;
      if (vvrState === 'InProgress') {
        $log.error('Cannot cancel an event that is in progress');
        Notifier.createMessage('error', Messages.otnInvite.cancelErrorInProgress.message);
        return;
      }

      Events.delete({
        id: event.requestId
      }, function(response) {
        // CS1-3011 Cancel events in OTNHub
        Schedules.delete({
          eventId: event.requestId
        }, function(res) {
          $scope.selectedEvent.detailsView.showCancel = false;
          $location.search({
            selDate: $filter('date')($scope.model.selDate),
            selEventId: event.requestId
          });
          $log.info('Outlook event cancelled - ID: ' + res);
        });

        $log.info('Event deleted - ID: ' + response);

        // Event list may get refreshed. This ensures we're updating the correct object
        const listEvent = getListEvent(event.requestId);
        if (listEvent) {
          listEvent.status = 'CANCELLED';
          listEvent.isCallable = false;
          initListView(listEvent);
          initDetailsView(listEvent);
        }

        Notifier.createMessage('confirmation', Messages.otnInvite.cancelSuccess.message);
      }, function(rejection) {
        $log.debug(rejection);
        Notifier.createMessage('error', Messages.otnInvite.msg41.message, JSON.stringify(rejection));
        $log.error('Unable to cancel the event');
      });
    });
  };

  $scope.updateEvent = (event, changes) => {
    return new Promise((resolve, reject) => {
      updateEventVvr(event).then(() => {
        const vvrState = event.vvr.vvrData.state;
        if (vvrState === 'InProgress') {
          reject({
            data: {
              error: 'E_EVENT_INPROGRESS'
            }
          });
          return;
        }

        Events.update({
            id: event.requestId
          },
          changes,
          (response) => resolve(response),
          (rejection) => reject(rejection)
        );
      });
    });
  };

  $scope.$on('host_site_event_created', function(event, eventDetails) {
    $scope.model.delegator = $scope.getDelegatorByUserId(eventDetails.selectedConsultant.userId);
    $scope.onChangeDelegator();
    $location.search('selDate', $filter('date')(eventDetails.eventDate));
    $scope.getEventList(eventDetails.eventDate);
  });

  $scope.$on('host_site_event_edited', function(event, eventDetails) {
    $location.search({
      selDate: $filter('date')(eventDetails.selectedDate),
      selEventId: eventDetails.eventId
    });
    $scope.getEventList(eventDetails.selectedDate);
  });

  $scope.selectEventDetails = function() {
    if ($scope.selectedEventId && $scope.model.todaysEvents && $scope.model.todaysEvents.length > 0) {
      var event = $scope.model.todaysEvents.find(e => e.requestId === parseInt($scope.selectedEventId));

      if (event) {
        $scope.showDetail(event);
      }
    }
  };

  $scope.editUserSettings = function() {
    UserPcvcPrefSettingsService.open($scope.selectedEvent.preferences.userId);
  };

  $scope.getEventTimeLabel = function(start, end) {
    var start = $filter('date')(start, 'HH:mm');
    var end = $filter('date')(end, 'HH:mm');

    return `${start}-${end}`;
  };

  $scope.showPatientLetter = function(eventId) {
    $log.debug('$scope.showPatientLetter() id:' + eventId);
    EventsService.navigateToPatientLetter(eventId);
  };

  $scope.refreshEventListParticipants = function() {
    if ($scope.isSelectedDatePast()) {
      $log.warn('Participants not being fetched. Selected date in the past.');
      return;
    }
    const todaysEvents = $scope.model.todaysEvents || [];
    const eventIds = todaysEvents.map((event) => event.requestId);
    EventsService.fetchEventListParticipants(eventIds);

    // Handles the case of the list refresh button being clicked when an event is selected
    $scope.refreshSelectedEventParticipants();
  };

  /**
   * Refreshes selected event participants and VVR
   */
  $scope.refreshSelectedEventParticipants = function() {
    if (!$scope.selectedEvent) {
      return;
    }
    CallService.fetchRTEventParticipantsStatus($scope.selectedEvent);
    updateEventVvr($scope.selectedEvent);
  };

  $scope.init();

});
